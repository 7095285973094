<template>
  <div class="auth__login">
    <h1 class="auth__login-title">Login</h1>
    <form class="auth__login-form" @submit.prevent="$emit('submit', loginInfo)">
      <input
        v-model="loginInfo.email"
        required
        @input="$emit('email', loginInfo.email)"
        type="text"
        class="auth__login-input"
        placeholder="Enter Email Address..."
      />
      <input
        v-model="loginInfo.password"
        required
        @input="$emit('password', loginInfo.password)"
        type="password"
        class="auth__login-input"
        placeholder="Password"
      />
      <button type="submit" class="auth__login-btn">Login</button>
    </form>
  </div>
</template>
<script>
export default {
  props: ["login", "password"],
  data() {
    return {
      loginInfo: {
        email: this.login,
        password: this.password,
      },
    };
  },
};
</script>

<style scoped>
.auth__login {
  font-size: 14px;
}

.auth__login-title {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 19px;
  font-weight: 500;
}
.auth__login-form {
  width: 100%;
  margin-bottom: 10px;
}
.auth__login-input {
  width: 100%;
  display: block;
  border-radius: 10rem;
  padding: 16px 24px;
  border: 1px solid #f3f3f3;
  margin-bottom: 16px;
}

.auth__login-input::placeholder {
  color: rgb(180, 179, 179);
}
.auth__login-input:focus {
  outline: 1px solid #1991eb;
}

.auth__login-btn {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #1991eb;
  border: none;
  border-radius: 10rem;
  padding: 0.75rem 1rem;
  transition: all 0.2s linear;
  cursor: pointer;
}
.auth__login-btn:hover {
  background-color: #157dcc;
  transition: all 0.2s linear;
}

@media (max-width: 991px) {
  .auth__login-input {
    padding: 15px 20px;
  }
}
@media (max-width: 767px) {
  .auth__login {
    font-size: 13px;
  }
}
@media (max-width: 499px) {
  .auth__login-title {
    font-size: 17px;
  }
  .auth__login-input {
    padding: 14px 18px;
    margin-bottom: 14px;
  }
}
</style>
