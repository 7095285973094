<template>
  <login-root />
</template>
<script>
import "./assets/css/normalize.css";
import "./assets/css/fonts.css";
import LoginRoot from "@/components/Login/LoginRoot.vue";
export default {
  name: "App",
  components: { LoginRoot },
};
</script>
<style>
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  letter-spacing: 0.7px;
  background-color: #f3f4f5;
  color: #181a3f;
}
.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}
.title {
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.2px;
}
.outline-btn {
  display: inline-block;
  border: 2px solid #181a3f;
  padding: 10px 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: inherit;
  transition: all 0.3s linear;
}
.outline-btn:disabled {
  color: #bbb;
  border-color: #bbb;
  transition: all 0.3s linear;
}
.fullcolor-btn {
  background: #ff7549;
  padding: 10px 20px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background-color: rgb(197, 200, 198);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.ace_scrollbar.ace_scrollbar-v {
  width: 3px !important;
}
.ace_scrollbar.ace_scrollbar-h {
  height: 3px !important;
}
.router-link-exact-active.router-link-active {
  font-weight: 700;
}
</style>
