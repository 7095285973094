<template>
  <div class="auth" id="auth">
    <div class="auth__wrapper">
      <div class="auth__field">
        <div class="auth__card">
          <div class="auth__col auth__col-logo">
            <div class="auth__logo" v-if="logoVisible" @click="toggleLogo">
              <img
                src="../../assets/img/cormedical_logo.png"
                alt="cormedical logo"
              />
            </div>

            <logo-cat v-if="!logoVisible" />
          </div>
          <div class="auth__col">
            <email-login
              :login="login"
              :password="password"
              @email="getEmail"
              @password="getPassword"
              @submit="onSubmit"
            />
            <div class="auth__telegram">
              <div class="auth__divider">
                <span>or</span>
              </div>
              <telegram-login
                mode="callback"
                :telegram-login="bot"
                requestAccess="write"
                @callback="loginByTelegram"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="auth__error" v-if="loginError">
        <div class="auth__error-message">user or password invalid</div>
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import EmailLogin from "./EmailLogin.vue";
import LogoCat from "./LogoCat.vue";
import TelegramLogin from "./TelegramLogin.vue";
export default {
  components: {
    EmailLogin,
    TelegramLogin,
    LogoCat,
  },
  data() {
    return {
      logoVisible: true,
      login: null,
      password: null,
      processLogin: false,
      loginError: false,
      bot: null,
    };
  },
  methods: {
    toggleLogo() {
      this.logoVisible = false;
      setTimeout(() => {
        this.logoVisible = true;
      }, 5000);
    },
    getEmail(email) {
      this.login = email;
    },
    getPassword(password) {
      this.password = password;
    },
    loginByTelegram(json_telegram) {
      let href = document.location.href;
      let idx = href.indexOf("cor_wgs=");
      let cor_wgs = href.slice(idx + 8);
      console.log(cor_wgs);
      axios
        .post(
          `${process.env.VUE_APP_AUTH}/login/widget/login_by_telegram`,
          json_telegram,
          {
              headers: {
                "cor-wgs": decodeURIComponent(window.atob(cor_wgs)),
              },
            }
        )
        .then((resp) => {
          console.log(resp);
          if (resp) window.close();
        })
        .catch((error) => {
          if (error) {
            this.loginError = true;
            setTimeout(() => {
              this.loginError = false;
              // this.processLogin = false;
            }, 2000);
          }
        });
    },
    onSubmit(info) {
      let href = document.location.href;
      let idx = href.indexOf("cor_wgs=");
      let cor_wgs = href.slice(idx + 8);
      console.log(cor_wgs);
      if (cor_wgs) {
        axios
          .post(
            `${process.env.VUE_APP_AUTH}/login/widget/login_by_password`,
            {
              login: info.email,
              password: info.password,
            },
            {
              headers: {
                "cor-wgs": decodeURIComponent(window.atob(cor_wgs)),
              },
            }
          )
          .then((resp) => {
            if (resp.data.status === true) {
              window.close();
            }
          })
          .catch((error) => {
            if (error) {
              this.loginError = true;
              setTimeout(() => {
                this.loginError = false;
                // this.processLogin = false;
              }, 2000);
            }
          });
      }
    },
  },
  created() {
    this.bot = process.env.VUE_APP_BOT;
  },
};
</script>
<style scoped>
.auth {
  width: 100vw;
  min-height: 100vh;
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe);
  background-size: cover;
}
.auth__wrapper {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}
.auth__field {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
}
.auth__card {
  background-color: #fff;
  border-radius: 2px;
  padding: 50px 40px 70px;
  max-width: 930px;
  width: 100%;
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  column-gap: 6rem;
}
.auth__logo {
  padding-top: 20px;
  max-width: 290px;
  margin: 0 auto;
}
.auth__logo img {
  width: 100%;
}

.auth__col {
  text-align: center;
}
.auth__col-logo {
  position: relative;
  text-align: center;
  height: 300px;
}

.auth__telegram {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.auth__divider {
  position: relative;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

.auth__divider:before,
.auth__divider:after {
  position: absolute;
  content: "";
  width: 45%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  top: 45%;
}
.auth__divider:before {
  left: 0;
}
.auth__divider :after {
  right: 0;
}
.auth__error {
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.auth__error-message {
  background-color: rgb(236, 46, 46);
  color: #fff;
  display: block;
  padding: 10px 15px;
  border-radius: 3px;
}

.fade-enter-active {
  animation: fade-in 0.5s;
}
.fade-leave-active {
  animation: fade-out 0.5s;
}
@keyframes fade-in {
  0% {
    top: -40px;
  }
  100% {
    top: 80px;
  }
}
@keyframes fade-out {
  0% {
    top: 80px;
  }
  100% {
    top: -40px;
  }
}

@media (max-width: 991px) {
  .auth__field {
    padding-top: 0;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }
  .auth__card {
    max-width: 540px;
    grid-template: 1fr / 1fr;
  }
  .auth__col-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  .auth__divider {
    font-size: 14px;
  }
  .auth__error-message {
    font-size: 12px;
  }
}
@media (max-width: 499px) {
  .auth__card {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
